<template lang="">
  <!-- SECTION tổng tiền thanh toán -->
  <BCard body-class="p-50">
    <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
      <IAmIcon
        icon="dollar"
        size="25"
        class="p-1 icon-card"
        @dblclick.native.prevent="$emit('toggle-button')"
      />
      <span class="font-medium-3 m-0 fw-700">
        {{ $t('invoice.create.payment.title') }}
      </span>
    </div>

    <div class="d-flex-between gap-1">
      <BRow class="px-75 flex-1">
        <!-- ANCHOR Tổng tiền thanh toán -->
        <b-col
          cols="12"
          class="px-25 flex-1"
          md="3"
        >
          <b-form-group
            :label="$t('invoice.create.payment.totalAmount')"
            label-for="totalAmount"
          >
            <IAmInputMoney
              id="totalAmount"
              :value-money="getTotalAmount"
              :placeholder="$t('invoice.create.payment.totalAmount')"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <!-- ANCHOR Cần thanh toán thêm -->
        <b-col
          cols="12"
          class="px-25 flex-1"
          md="3"
        >
          <b-form-group
            :label="$t('invoice.create.payment.totalAmountNeedToPay')"
            label-for="totalAmountNeedToPay"
          >
            <IAmInputMoney
              id="totalAmountNeedToPay"
              :value-money="needToPayAmount"
              :placeholder="$t('invoice.create.payment.phTotalAmountNeedToPay')"
              :disabled="!isEditing"
              :on-blur="val => needToPayAmount = Number(val)"
            />
          </b-form-group>
        </b-col>

        <!-- ANCHOR Đại lý nợ/bên mua -->
        <b-col
          cols="12"
          class="px-25 flex-1"
          md="3"
        >
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.create.payment.agencyReceive')"
            rules="required"
            vid="agencyReceive"
          >
            <b-form-group
              label-for="agencyReceive"
              :class="{ 'is-invalid': errors[0] }"
            >
              <template #label>
                {{ $t('invoice.create.payment.agencyReceive') }}
                <span class="text-danger">(*)</span>
              </template>
              <v-select
                id="agencyReceive"
                :value="invoiceData?.agencyReceive"
                style="font-size: 1rem;"
                :name="$t('invoice.create.payment.agencyReceive')"
                :options="agencyReceiveOptions"
                :reduce="val => val"
                append-to-body
                :calculate-position="withPopper"
                label="agencyCode"
                filterable
                :disabled="!isEditing"
                :clearable="true"
                :placeholder="$t('invoice.create.payment.agencyReceive')"
                :loading="loadingAgencyReceive"
                @input="(val) => $emit('update:invoiceData', { ...invoiceData, agencyReceive: val })"
                @open="onOpenAgencyReceive"
              >
                <template #option="data">
                  <span class="d-block font-weight-bold text-truncate text-uppercase">
                    <span class="font-weight-bolder">{{ data?.agencyCode }}</span> <small>({{ data.agencyName }})</small>
                  </span>
                </template>

                <template #selected-option="data">
                  <span class="d-block font-weight-bold text-truncate text-uppercase">
                    <span class="font-weight-bolder">{{ data?.agencyCode }}</span> <small>({{ data.agencyName }})</small>
                  </span>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                target="agencyReceive"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <!-- ANCHOR Đại lý có / bên bán-->
        <b-col
          cols="12"
          class="px-25 flex-1"
          md="3"
        >
          <b-form-group :label="$t('invoice.create.payment.agencyIssue')">
            <v-select
              id="agencyIssue"
              :value="invoiceData?.agencyIssue"
              style="font-size: 1rem;"
              :options="agencyIssueOptions"
              label="agencyCode"
              append-to-body
              :calculate-position="withPopper"
              :disabled="!isEditing"
              :clearable="true"
              :placeholder="$t('invoice.create.payment.agencyIssue')"
              :reduce="val => val"
              @input="(val) => $emit('update:invoiceData', { ...invoiceData, agencyIssue: val })"
            >
              <template #option="data">
                <span class="d-block font-weight-bold text-truncate text-uppercase">
                  <span class="font-weight-bolder">{{ data?.agencyCode }}</span> <small>({{ data.agencyName }})</small>
                </span>
              </template>

              <template #selected-option="data">
                <span class="d-block font-weight-bold text-truncate text-uppercase">
                  <span class="font-weight-bolder">{{ data?.agencyCode }}</span> <small>({{ data.agencyName }})</small>
                </span>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </BRow>
      <div class="d-flex-center">
        <BButton
          variant="primary"
          size="sm"
          class="p-50 text-nowrap"
          pill
          disabled
        >
          {{ $t('invoice.create.payment.addDocuments') }}
        </BButton>
      </div>
    </div>
  </BCard>
  <!-- !SECTION  -->
</template>
<script>
import {
  BCard, BCol, BRow, BFormGroup, BButton,
  BTooltip,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import { createPopper } from '@popperjs/core'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationProvider } from 'vee-validate'

import { apiAgencies } from '@/api'
import store from '@/store'

import { onlyUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BButton,
    BTooltip,
    ValidationProvider,
    vSelect: () => import('vue-select'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
  },
  props: {
    invoiceData: {
      type: Object,
      default: () => { },
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    isReplace: {
      type: Boolean,
      default: false,
    },
    isAdjust: {
      type: Boolean,
      default: false,
    },
    isDuplicate: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const getTotalAmount = computed(() => store.getters['app-invoice/getTotalAmount'])
    const getOriginTotalAmount = computed(() => store.getters['app-invoice/getOriginTotalAmount'])
    const totalDifferenceAmount = computed(() => getTotalAmount.value - getOriginTotalAmount.value)
    const needToPayAmount = computed({
      get: () => store.getters['app-invoice/getNeedToPayAmount'],
      set: val => store.dispatch('app-invoice/setNeedToPayAmount', val),
    })

    // agency issue
    const agencyIssueOptions = computed(() => {
      const rs = [agencyData.value]
      if (agencyData.value?.parentAgency) {
        rs.push(agencyData.value.parentAgency)
      }
      return cloneDeep(rs)
    })

    // agency receive
    const agencyReceiveOptions = ref([])

    const loadingAgencyReceive = ref(false)
    function onOpenAgencyReceive() {
      if (!agencyData.value?.id || loadingAgencyReceive.value) return
      loadingAgencyReceive.value = true
      apiAgencies
        .getChild(agencyData.value?.id, {
          isActive: true,
        })
        .then(response => {
          agencyReceiveOptions.value = [...response.data.items, agencyData.value]
        })
        .finally(() => {
          loadingAgencyReceive.value = false
        })
    }
    return {
      onlyUpperCaseFormatter,
      totalDifferenceAmount,
      needToPayAmount,
      agencyIssueOptions,
      agencyReceiveOptions,
      onOpenAgencyReceive,
      loadingAgencyReceive,
      getTotalAmount,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = `${Number(width.slice(0, -2) * 2)}px`
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],

      })
      return () => popper.destroy()
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
